import { ScreenPosition } from "cadius-cadlib";
import { BaseInteractor, Cad } from "cadius-components";
import { log } from "cadius-stdlib";
import { Vector3 } from "three";

import * as act from "../actions";
import { Cad3DAppControl } from "../controls/controls";
import {
  CadiusDispatch,
  CadiusThunkAction,
  IApplicationState,
} from "../interfaces";
import { dispatch } from "../store";

const nopControl = new Cad3DAppControl((state: IApplicationState, v: any) => state);

export const NEW_NOTE_INTERACTOR = "NewNote";

/**
 * Show a popover that contains an editable element that allows the user to
 * create a new not or editing an old one.
 *
 * The reason why this action is defined here instead of being defined somewhere
 * like `actions/notes` is because this action should by dispatched only by this
 * interactor, not by any UI components.
 *
 * Note: this action needs to be async because we dispatch it from an
 * interactor (and our interactors can currently dispatch only async actions).
 */
const startWritingNote = (pos: ScreenPosition, p: Vector3): CadiusThunkAction<void> => {
  return async (cadiusDispatch: CadiusDispatch): Promise<void> => {
    setTimeout(() => {
      cadiusDispatch({ payload: { p, pos }, type: act.START_WRITING_NOTE });
    }, 1);
  };
};

/**
 * Interactor for the creation of a note between the stylist and the modeller.
 *
 * This interactor captures all mouse events until it's in the interactor stack,
 * so the user cannot perform any operation (e.g. move or zoom the project Last)
 * until he dismisses - either by clicking cancel or confirm - the popover to
 * create a note.
 */
export class NewNoteInteractor extends BaseInteractor<any> {
  constructor() {
    super(NEW_NOTE_INTERACTOR, nopControl);
  }

  protected onMouseMotionEvent(evt: Cad.MouseMotionEvent) {
    return true;
  }

  protected onMousePressEvent(evt: Cad.MousePressEvent) {
    return true;
  }

  protected onMouseReleaseEvent(evt: Cad.MouseReleaseEvent) {
    return true;
  }

  protected onMouseWheelEvent(evt: Cad.MouseWheelEvent) {
    return true;
  }

  protected onMouseClickEvent(evt: Cad.MouseClickEvent) {
    if (this.isTriggerEvent(evt)) {
      this.startWritingNoteIfClickIntersectsLast(evt);
    }
    return false;
  }

  private isTriggerEvent(evt: Cad.MouseButtonEvent) {
    const condition =
      evt.button === Cad.MouseButton.LEFT && (evt.hasNoModifier());
    return condition;
  }

  private startWritingNoteIfClickIntersectsLast(evt: Cad.MouseButtonEvent) {
    const { project, view } = Cad3DAppControl.state;

    log(`${NEW_NOTE_INTERACTOR} interactor: get camera from app state.`);
    const ray = view.cadView.castRay(evt.position);
    const intersection = project.fundamentalEntities.last.geometry().intersectLoopPoint(ray);
    if (intersection) {
      dispatch(startWritingNote(evt.position, intersection));
    }
  }
}
