import { MaterialDB } from "cadius-backend";
import {
  Constructable,
  Render3DContext,
  RenderFunction,
  RenderingOptions,
  SharingOpts,
} from "cadius-cadlib";
import { primitives } from "cadius-components";
import {
  BackMiddleEdgePath,
  ConeEdgePath,
  DashedStitch,
  EmptyInsole,
  FeatherEdgePath,
  FrontMiddleEdgePath,
  GeodesicPolyLinePath,
  Last,
  ParallelDashedStitch,
  Path,
  PerimeterPath,
  PieceSolid,
  PipedSeamSolid,
  SeamToolPath,
  TypeInfo,
} from "cadius-db";

import * as act from "../actions";

export function makeRenderFunction(
  entityName: string,
  typeInfo: TypeInfo,
  entityType: "Asset" | "Path" | "ProjectionSurface" | "Solid" | "SurfaceCap" | "ToolPath",
  materialDb?: MaterialDB
): RenderFunction {
  // The following do not have a geometric representation
  if (["Asset", "SurfaceCap"].indexOf(entityType) > -1) {
    return (() => ({ objects: [], geometries: new Set(), materials: new Set(), textures: new Set() }));
  }

  switch (entityName) {
    case Last.name: {
      if (!materialDb) {
        throw new Error("ASSERT: trying to create a rendering function for last without a valid materialDb");
      }
      return ((
        e: Constructable,
        ctx: Render3DContext,
        rOpts?: RenderingOptions,
        e2?: Constructable,
        mOpts?: SharingOpts
      ) => {
        if (ctx.filters.showLast) {
          return primitives.renderSolid(e as primitives.SolidEntity, materialDb).renderResult;
        }
        return {
          // TODO this should keep a reference to the last and change its visibility value instead
          objects: [],
        };
      });
    }

    case PerimeterPath.name:
    case EmptyInsole.name: {
      return (() => ({ objects: [] }));
    }

    case GeodesicPolyLinePath.name:
    case BackMiddleEdgePath.name:
    case ConeEdgePath.name:
    case FeatherEdgePath.name:
    case FrontMiddleEdgePath.name:
    case ParallelDashedStitch.name:
    case DashedStitch.name:
    case SeamToolPath.name: {
      // this conversion is needed because styles are chosen among a specific set of possible strings.
      const style = typeInfo.name as any;
      return ((
        e: Constructable,
        ctx: Render3DContext,
        rOpts?: RenderingOptions,
        e2?: Constructable,
        mOpts?: SharingOpts
      ) => primitives.renderCurve((e as Path).geodesic().points, { style }));
    }

    case PipedSeamSolid.name:
    case PieceSolid.name: {
      if (!materialDb) {
        throw new Error("ASSERT: trying to create a rendering function for Piece without a valid materialDB");
      }
      return ((
        e: Constructable,
        ctx: Render3DContext,
        rOpts?: RenderingOptions,
        e2?: Constructable,
        mOpts?: SharingOpts
      ) => {
        const { renderResult: renderRes, texturesReady } = primitives.renderSolid(
          e as primitives.SolidEntity,
          materialDb,
          { envMaps: ctx.envMaps }
        );
        Promise.all(texturesReady).then(ctx.refresh);
        return renderRes;
      });
    }
    default: {
      throw new Error(`ASSERT: found entity ${entityName}, type ${entityType}`);
    }
  }
}

export const registerRenderingFunction = (
  entityName: string,
  typeInfo: TypeInfo,
  entityType: "Asset" | "Path" | "ProjectionSurface" | "Solid" | "SurfaceCap" | "ToolPath",
  materialDB?: MaterialDB
) => {
  return {
    payload: {
      entityName,
      render: makeRenderFunction(entityName, typeInfo, entityType, materialDB),
    },
    type: act.REGISTER_RENDERING_FUNCTION,
  };
}
