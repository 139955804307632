import { client, PicassoClient, StyleProject } from "cadius-backend";
import { HalfEdgeMeshLast, loadOBJ } from "cadius-cadlib";
import {
  EnvironmentMapSpec,
  NewProjectFormData,
  requests as req,
} from "cadius-components";
import {
  createMigrationMap,
  deserialize,
  Entity,
  extractMissingFundamentalEdges,
  isPath,
  isPerimeterPath,
  isPieceSolid,
  isSolid,
  isTool,
  Project,
  rebuildAfterDeserialization,
  serialize,
} from "cadius-db";
import { log } from "cadius-stdlib";

import * as act from "../actions";
import {
  CadiusDispatch,
  CadiusThunkAction,
  IAction,
  IApplicationState,
} from "../interfaces";
import { ProjectManager } from "../projectManager";
import { fetchEnvironmentMap } from "./envmaps";
import { appError } from "./errors";
import { hideDialog, showDialog } from "./ui";

export const fetchProjects = (): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    try {
      const projects = await req.get(`projects/`);
      dispatch({ type: act.FETCH_PROJECTS_SUCCEEDED, payload: { projects } });
    } catch (err) {
      log("Failed to fetch projects", err.toString());
    }
  };
};

export const saveProject = (projectId: string, project: Project) => {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    try {
      const response = await req.post(
        `projects/${projectId}`,
        serialize(project)
      );
      dispatch({
        payload: {
          // when saving a project response contains the id of the new project,
          // but when the project already existed null is returned
          id: projectId || response.id,
          project,
        },
        type: act.SAVE_PROJECT,
      });
    } catch (reason) {
      log("Error while saving the project", reason);
    }
  };
};

export const deleteProject = (projectId: string) => {
  const pc = client();
  return async (dispatch: CadiusDispatch) => {
    try {
      await pc.styles.delete(projectId);
    } catch (err) {
      const info = `Failed to delete project ${projectId}`;
      const detail = err.message;
      dispatch(appError(info, detail));
    }
  };
};

export const setProject = (
  projectId: string,
  project: Project
): IAction => {
  return { type: act.SET_PROJECT, payload: { projectId, project } };
};

export const setProjectData = (projectId: string): CadiusThunkAction<void> => {
  const pc = client();
  return async (dispatch: CadiusDispatch) => {
    const stylePrj = await pc.styles.fetch(projectId);
    let remeshingName: string | undefined;
    if (stylePrj.remesh_project_id) {
      const remeshingPrj = await pc.remeshes.fetch(stylePrj.remesh_project_id);
      remeshingName = remeshingPrj.name;
    }

    dispatch({
      payload: { projectId, projectName: stylePrj.name, remeshingProjectName: remeshingName },
      type: act.SET_PROJECT_DATA,
    });
  }
};

/**
 * @brief Add a new entity to the project. It updates the scene if needed.
 *
 * @param entity The entity to add
 */
export const addEntity = (
  entity: Entity
): CadiusThunkAction<void> => {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    dispatch({ type: act.ADD_ENTITY, payload: { entity } });
  };
};

export const setprojectDigests = (): CadiusThunkAction<void> => {
  const pc = client();
  return async (dispatch: CadiusDispatch): Promise<void> => {
    try {
      const projectDigests = await pc.styles.fetchList();
      dispatch({ type: act.SET_PROJECT_DIGESTS, payload: { projectDigests } });
    } catch (err) {
      const info = "Failed to fetch Picasso style projects.";
      const detail = err.toString();
      dispatch(appError(info, detail));
    }
  };
};

const fetchHalfEdgeMeshLast = async (pc: PicassoClient, project: StyleProject) => {
  // TODO: replace with const buffer = pc.styles.fetchShowLast(project); when available in the API.
  const objString = await pc.remeshes.fetchShoeLastMesh({ url: project.last_url! });
  const objFile = loadOBJ(objString);
  if (objFile.models.length !== 1) {
    throw new Error("ASSERT: OBJ file doesn't hold one single model");
  }
  const mesh = HalfEdgeMeshLast.fromOBJModel(objFile.models[0]);
  return mesh;
};

const fetchProjectDBAndRebuildProject = async (pc: PicassoClient, project: StyleProject) => {
  const objString = await pc.styles.fetchCadiusDB(project);
  const json = JSON.parse(objString);
  const projectManager = new ProjectManager(project.id);
  return rebuildAfterDeserialization(deserialize(json, createMigrationMap()), projectManager);
};

export function fetchProject(projectId: string): CadiusThunkAction<void> {

  /**
   * This function let the GUI refresh when the project DB has been fetched in a way that entities
   * are rendered progressively.
   *
   * When a project DB has been fetched, this must be stored in the state dispatching `SET_PROJECT`.
   * Thus it would be sufficient just to call `setProject(projectId, projectDB)`.
   *
   * However, this implies the scene to be built, which itself implies generating the geometries of all
   * the entities. Such geometries can be heavy to compute, casing the GUI to freeze for a notable amount
   * of time.
   *
   * We can, instead, cause the scene (and the rendering) to be update in a *one-by-one* fashion.
   * This way, the GUI freezes for just the time of computing a single entity geometry (which may still
   * be notable, but not as the time of computing the whole stuff). Moreover, it allows for providing
   * the user with information about what it is computing, without giving a feeling of broken UI.
   *
   * To implement this method, we split the project DB into multiple projects, incrementally built by
   * adding entities one by one. Each sequential project is then set into the store with the usual action.
   *
   * In particular, due to the dependence hierarchy among the entities, these must be extracted in a way that
   * when an entity is added, all those it depends on must already have been added:
   * 1. extract the fundamental entities `Last`, the `SoleAsset` and the `Stitch`s at once
   * 2. extract the fundamental `FeatherEdgePath`, `ConeEdgePath`, `BackMiddleEdgePath`, `FrontMiddleEdgePath`
   *    at once (these aren't heavy to compute and are actually always present in a remeshed Last for sure)
   * 3. extract the remeaning fundamental entities, i.e. the solids such as the `Insole` (together with the
   *    `SurfaceCap` they depend on) one at a time (these are heavy to compute)
   * 4. extract all the `Path`s
   * 5. extract all the `ToolPath`s, which depend on the `Path`s
   * 6. extract all the remaning solids (`PieceSolid`s and `PipedSolid`s, as of version 9 of `Project`)
   *
   * @param {CadiusDispatch} dispatch The dispatcher is used to store the progressive project DB and to show
   * messages for the user.
   * @param {() => IApplicationState} getState The function that returns the current application state. This function
   * uses `getState()` to retrieve the updated project after each successfull `add` operation.
   * @param {string} title The title of each message for the user.
   * @param {Project} projectDB The entire project DB fetched from the backend.
   * @returns {Promise<void>} This is an asynchronous process, since at each step the project must be stored and,
   * especially, the GUI must be refreshed (which implies to interrupt the current sequence of instructions so that
   * the control is taken by the rendering loop, and then to go on with the following instructions).
   * Thus this function returns a promise.
   */
  async function loadProjetDBProgressively(
    dispatch: CadiusDispatch,
    getState: () => IApplicationState,
    title: string,
    projectDB: Project
  ): Promise<void> {
    const nEntities = projectDB.length;

    let progressiveProject = new Project(projectDB.details, [
      projectDB.fundamentalEntities.last,
      projectDB.fundamentalEntities.soleAsset,
      ...projectDB.fundamentalEntities.stitches,
    ]);
    dispatch(setProject(projectId, progressiveProject));

    const fundamentalEntities = projectDB.fundamentalEntities.toEntityArray().filter((e) => !progressiveProject.has(e));

    await dispatch(showDialog(`Loading fundamental paths ...`, progressiveProject.length / nEntities * 100, title));
    for (const e of fundamentalEntities.filter((fe) => isPath(fe))) {
      await dispatch(addEntity(e));
      progressiveProject = getState().project;
    }

    for (const s of fundamentalEntities.filter((e) => isSolid(e))) {
      const closure = projectDB.transposeClosure(s);
      const progress = progressiveProject.length / nEntities * 100;
      await dispatch(showDialog(`Loading fundamental solid ${s.getTypeInfo().name} ...`, progress, title));
      for (const c of closure) {
        if (!progressiveProject.has(c)) {
          await dispatch(addEntity(c));
          progressiveProject = getState().project;
        }
      }
    }

    for (const e of projectDB) {
      if (!isPath(e) || isPerimeterPath(e) || progressiveProject.has(e)) {
        continue;
      }
      await dispatch(showDialog(`Loading paths ...`, progressiveProject.length / nEntities * 100, title));
      await dispatch(addEntity(e));
      progressiveProject = getState().project;
    }

    for (const e of projectDB) {
      if (!isTool(e) || progressiveProject.has(e)) {
        continue;
      }
      await dispatch(showDialog(`Loading tool paths ...`, progressiveProject.length / nEntities * 100, title));
      await dispatch(addEntity(e));
      progressiveProject = getState().project;
    }

    for (const e of projectDB) {
      if (!isSolid(e) || progressiveProject.has(e)) {
        continue;
      }
      await dispatch(showDialog(`Loading solid ${e.name} ...`, progressiveProject.length / nEntities * 100, title));
      if (isPieceSolid(e)) {
        for (const b of e.boundaries) {
          if (!progressiveProject.has(e)) {
            await dispatch(addEntity(b));
            progressiveProject = getState().project;
          }
        }
      }
      await dispatch(addEntity(e));
      progressiveProject = getState().project;
    }

    await dispatch(showDialog("Loaded!", 100, title));

    setTimeout(() => {
      dispatch(hideDialog());
    }, 3000);
  }

  return async (dispatch: CadiusDispatch, getState: () => IApplicationState): Promise<void> => {
    // Set an empty project to avoid showing the current project while loading a new one.
    dispatch({ type: act.RESET_INITIAL_STATE });

    await dispatch(showDialog(`Fetching style project ${projectId} ...`, undefined, "Fetch Style Project"));

    const pc = client();

    let project: StyleProject;
    try {
      project = await pc.styles.fetch(projectId);
    } catch (err) {
      const info = `Failed to fetch style project ${projectId}`;
      const detail = err.toString();
      dispatch(appError(info, detail));
      await dispatch(hideDialog());
      return;
    }

    const promise = Promise.all([
      fetchHalfEdgeMeshLast(pc, project),
      fetchProjectDBAndRebuildProject(pc, project),
    ]);

    const title = `Fetch Style Project '${project.name}'`;

    await dispatch(showDialog(`Rebuilding project from mesh ...`, undefined, title));
    let mesh: HalfEdgeMeshLast;
    let projectDB: Project;
    try {
      [mesh, projectDB] = await promise;
    } catch (err) {
      const info = `Failed to fetch shoe last mesh or project DB`;
      const detail = err.toString();
      dispatch(appError(info, detail));
      await dispatch(hideDialog());
      return;
    }

    await dispatch(showDialog(`Rebuilding environment maps ...`, undefined, title));
    const envMapsSpecs = getState().envMapsSpecs;
    if (envMapsSpecs) {
      const envMapSpec = envMapsSpecs.find((e: EnvironmentMapSpec) => {
        return e.id === projectDB.details.envMapId;
      });
      if (envMapSpec !== undefined) {
        dispatch(fetchEnvironmentMap(envMapSpec));
      }
    }

    await dispatch(showDialog(`Extracting missing fundamental edges ...`, 0, title));
    projectDB = extractMissingFundamentalEdges(projectDB, mesh);

    // load the project DB and update the GUI progressively
    await loadProjetDBProgressively(dispatch, getState, title, projectDB);
  };
}

/**
 * Get data from the NewProjectForm component and create a new Project.
 *
 * The form data about to be submitted to the backend contains a `.cal` file as a Blob. This function creates a new
 * empty style project, then asks the backend to populate it with the result of a `.cal` conversion.
 */
export const onSubmitNewProject = (data: NewProjectFormData): CadiusThunkAction<void> => {
  const { calFile, projectName } = data;

  const pc = client();

  return async (dispatch: CadiusDispatch): Promise<void> => {
    let errorInfo: string = "";
    let errorDetail: string = "";
    let sPrj: picasso.StyleProject;

    try {
      // 1 create a new empty style project
      try {
        const info = `Uploading .cal file and creating ${projectName} project`;
        await dispatch(showDialog(info, undefined, "Cal upload"));
        sPrj = await pc.styles.create({ name: projectName });
      } catch (err) {
        log("Error creating a new Style project", err);
        errorInfo = `Failed to create project ${projectName}`;
        errorDetail = err.message;
        throw err;
      }

      // 2 import a `.cal` file in the newly created project
      try {
        const cal = await new Response(calFile).arrayBuffer();
        sPrj = await pc.styles.extractCal(sPrj.id, cal);
        dispatch(fetchProject(sPrj.id));
      } catch (err) {
        log(`Error converting the .cal file for project ${projectName}`, err);
        errorInfo = `Failed to create project ${projectName}`;
        errorDetail = "An error occurred while converting the cal file";

        // If an error occurred while importing the cal, we need to remove the style project we have just created.
        try {
          await pc.styles.delete(sPrj.id);
        } catch (e) {
          log(`Error removing style project ${projectName}  after import attempt failed`, err);
          errorInfo = `Failed to create project ${projectName}`;
          errorDetail = `Conversion failed and error occurred while deleting style project ${sPrj.id}`;
          throw e;
        }
        throw err;
      }
    } catch (e) {
      dispatch(appError(errorInfo, errorDetail));
      alert(`${errorInfo}\n${errorDetail}`);
      await dispatch(hideDialog());
    }
  };
};
