import { connect } from "react-redux";

import { selectNote } from "../actions/notes";
import { Note } from "../components/Note";
import { IApplicationState } from "../interfaces";

function mapStateToProps(state: IApplicationState) {
  return {};
}

const mapDispatchToProps = {
  selectNote,
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const NoteConnected = enhance(Note);
