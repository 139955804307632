import * as cadlib from "cadius-cadlib";
import { SolidMesh } from "cadius-cadlib";
import { Project } from "cadius-db";
import { actions, exportContext, registerStore } from "cadius-debug";
import * as geo from "cadius-geo";
import * as geo3d from "cadius-geo3d";
import {
  applyMiddleware,
  CombinedState,
  createStore,
  Middleware,
  Store,
  StoreEnhancer,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import reduxThunk from "redux-thunk";

import { HANDLE_CAD_EVENT, RESTORE_CAMERA } from "./actions";
import {
  toggleDecorations,
  toggleLast,
  toggleLines,
  togglePieces,
  toggleWireframe,
} from "./actions/toggles";
import { CadiusDispatch, IAction, IApplicationState } from "./interfaces";
import { rootReducer } from "./reducers";

const getStoreEnhancer = (debug?: boolean, ...middlewares: Middleware<unknown, {}>[]): StoreEnhancer => {
  if (debug) {
    const logger = createLogger({
      collapsed: true,
      duration: true,
      level: "log",
      predicate: (getState, action) => action.type !== HANDLE_CAD_EVENT,
    });

    const restoreCameraMiddleWare = () => {
      return (next: CadiusDispatch) => (action: IAction): IAction => {
        if (action.type === actions.RESTORE_CAMERA) {
          return next({ ...action, type: RESTORE_CAMERA });
        }
        return next(action);
      };
    };

    return composeWithDevTools(applyMiddleware(reduxThunk, logger, restoreCameraMiddleWare, ...middlewares));
  } else {
    return composeWithDevTools(applyMiddleware(reduxThunk, ...middlewares));
  }
};

declare module "cadius-debug" {
  interface IStoreState {
    project?: Project;
  }

  interface CadiusContext {
    cadlib: typeof cadlib;
    geo: typeof geo;
    geo3d: typeof geo3d;
    lastGeometry: () => SolidMesh;
    project: () => Project | null;

    toggleLast: () => void;
    toggleLines: () => void;
    togglePieces: () => void;
    toggleWireframe: () => void;
    toggleDecorations: () => void;
  }
}

export enum DebugSymbols {
  exported,
  unexported,
}

let _store: Store<IApplicationState> | undefined;

export function dispatch(action: any) {
  _store!.dispatch(action);
}

export const initStore = (
  debugSymbols: DebugSymbols = DebugSymbols.unexported,
  debug: boolean = false,
  ...middlewares: Middleware<unknown, {}>[]
) => {
  const storeEnhancer = getStoreEnhancer(debug, ...middlewares);

  _store = createStore(
    rootReducer,
    storeEnhancer
  );

  if (debugSymbols === DebugSymbols.exported) {
    registerStore({ ..._store, getView: () => _store!.getState().view.cadView });
    exportContext({
      cadlib,
      geo,
      geo3d,

      lastGeometry: () =>
        window.C.store!.getState().project?.fundamentalEntities.last.geometry(),
      project: () => window.C.store!.getState().project,

      toggleDecorations: () => window.C.store!.dispatch(toggleDecorations()),
      toggleLast: () => window.C.store!.dispatch(toggleLast()),
      toggleLines: () => window.C.store!.dispatch(toggleLines()),
      togglePieces: () => window.C.store!.dispatch(togglePieces()),
      toggleWireframe: () => window.C.store!.dispatch(toggleWireframe()),
    });
  }

  return _store;
};

export const getStore = (
  partialState: CombinedState<IApplicationState>,
  debug?: boolean,
  ...middlewares: Middleware<unknown, {}>[]
) => {
  return createStore(rootReducer, partialState, getStoreEnhancer(debug));
};
