import { RenderingRefreshCallback } from "cadius-components";
import { ActionCreator } from "redux";

import * as act from "../actions";
import { CadiusDispatch, CadiusThunkAction, IAction, IApplicationState } from "../interfaces";

/**
 * Signal the app that a refresh of the sources is needed.
 *
 * Dispatch this action when you want to trigger an imperative refresh of the
 * sources in the 3D scene. For example, when using textures, dispatch this
 * action in the onTextureLoaded callback, so as soon as the texture is loaded,
 * the app knows that the sources should be refreshed.
 */
export const refreshSources: ActionCreator<IAction> = () => {
  return { type: act.REFRESH_SOURCES };
};

/**
 * Thunk action creator that allows to add a rendering refresh callback into the state.
 *
 * This is supposed to be called by `View3D` component instances when their ThreeJS
 * renderer is instanced. The refresh callback is called asynchronously when there's no
 * change in the state but the rendering must be triggered anyways.
 * An esample of that is when the textures are fetched, then the rendering must be triggered
 * to refresh the frame buffer: in such case chaning the state for such triggering isn't
 * convenient at all, since we experienced really drammatic performance issues.
 * @export
 * @param {RenderingRefreshCallback} refreshCallback
 * @returns {CadiusThunkAction<void>}
 */
export function subscribeRenderingRefresher(refreshCallback: RenderingRefreshCallback): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    dispatch({
      payload: { refreshCallback },
      type: act.SUBSCRIBE_RENDERING_REFRESHER,
    });
  }
}

/**
 * Thunk action creator that allows to remove a rendering refresh callback from the state.
 *
 * This is supposed to be called by `View3D` component instances when they must be unmounted.
 * The refresh callback is called asynchronously when there's no change in the state but the
 * rendering must be triggered anyways.
 * An example of that is when the textures are fetched, then the rendering must be triggered
 * to refresh the frame buffer: in such case chaning the state for such triggering isn't
 * convenient at all, since we experienced really drammatic performance issues.
 * @export
 * @param {RenderingRefreshCallback} refreshCallback
 * @returns {CadiusThunkAction<void>}
 */
export function unsubscribeRenderingRefresher(refreshCallback: RenderingRefreshCallback): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    dispatch({
      payload: { refreshCallback },
      type: act.UNSUBSCRIBE_RENDERING_REFRESHER,
    });
  }
}

/**
 * Thunk action creator that triggers a re-render when there's no change of the state.
 *
 * The refresh callback is called asynchronously when there's no change in the state but the
 * rendering must be triggered anyways.
 * An esample of that is when the textures are fetched, then the rendering must be triggered
 * to refresh the frame buffer: in such case chaning the state for such triggering isn't
 * convenient at all, since we experienced really drammatic performance issues.
 *
 * @export
 * @returns {CadiusThunkAction<void>}
 */
export function refreshRendering(): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch, getState: () => IApplicationState): Promise<void> => {
    for (const refresh of getState().subscribedRefreshers) {
      refresh();
    }
  };
}

/**
 * Create the refresh callback, which needs the dispatch to trigger the rendering
 * refresh, to store into the state and been called when some async
 * processing ends and needs to re-render the scene. As an example, think of
 * when a texture is fetched and we need to refresh the view to show the solid
 * with the new material.
 * @export
 * @returns {CadiusThunkAction<void>}
 */
export function setRefresher(): CadiusThunkAction<void> {
  return async (dispatch: CadiusDispatch): Promise<void> => {
    dispatch({
      payload: {
        refresh: async (): Promise<void> => {
          return new Promise<void>((resolve) => {
            setTimeout(() => {
              dispatch(refreshRendering());
            }, 0);
          });
        },
      },
      type: act.SET_REFRESHER,
    });
  };
}
