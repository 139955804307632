import { NewProjectForm } from "cadius-components";
import { connect } from "react-redux";

import { onSubmitNewProject as onSubmit } from "../actions/projects";
import { IApplicationState } from "../interfaces";

function mapStateToProps(state: IApplicationState) {
  return {};
}

const mapDispatchToProps = {
  onSubmit,
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const NewProjectFormConnected = enhance(NewProjectForm);
