import { IProjectManager } from "cadius-db";

export class ProjectManager extends IProjectManager {
  public readonly projectID: string;

  constructor(projectID: string) {
    super();
    this.projectID = projectID;
  }
}
