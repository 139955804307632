import { ReactiveView } from "cadius-components";
import * as act from "../actions";
import { CadiusDispatch, CadiusThunkAction, IAction, UiMode } from "../interfaces";
import { refreshSources } from "./render";

export function showDialog(
  info?: string,
  progress?: number,
  title?: string
): CadiusThunkAction<void> {
  return (dispatch: CadiusDispatch): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch({
        payload: { info, title, open: true, progress },
        type: act.UPDATE_DIALOG,
      });
      dispatch(refreshSources()); // necessary to redraw the view as nobody does for `UPDATE_DIALOG`
      setTimeout(() => {
        resolve();
      }, 0);
    });
  };
};

export function hideDialog(): CadiusThunkAction<void> {
  return (dispatch: CadiusDispatch): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch({
        payload: { open: false },
        type: act.UPDATE_DIALOG,
      });
      dispatch(refreshSources()); // necessary to redraw the view as nobody does for `UPDATE_DIALOG`
      setTimeout(() => {
        resolve();
      }, 0);
    });
  };
};

/**
 * @brief Dispatched when the camera needs to be resized.
 *
 * @param width The new width
 * @param height The new height
 */
export function setCameraSize(
  cadView: ReactiveView,
  width: number,
  height: number
): IAction {
  return { payload: { cadView, width, height }, type: act.SET_CAMERA_SIZE };
};

export const changeUiMode = (uiMode: UiMode) => {
  return { payload: { uiMode }, type: act.CHANGE_UI_MODE };
};
