import { Creds, initClient } from "cadius-backend";
import { initializeGeo3d } from "cadius-geo3d";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { App } from "./containers/App";
import { DebugSymbols, initStore } from "./store";

import "./css/index.css";

// Variable injected in the bundle at build time (see webpack config).
declare var DEF_DEBUG_MODE: boolean;
if (DEF_DEBUG_MODE) {
  console.warn(`--- DEF_DEBUG_MODE: ${DEF_DEBUG_MODE} ---`);
}

/**
 * Credentials for the picasso client. At the moment we don't have
 * authentication and we simulate 2 users: u01 and s01 (s stands for stylist).
 * To use the application as a stylist, launch it with:
 * http://localhost:8080/?user=s01#/
 */
const creds: Creds =
  document.location.search.indexOf("user=s01") === 1 ? "s01" : "u01";

const initGeo3dAndPicassoClientThenRenderWhenReady = async () => {
  try {
    await initializeGeo3d();
    await initClient(creds);

    ReactDOM.render(
      <Provider store={initStore(DebugSymbols.exported, DEF_DEBUG_MODE)}>
        <App />
      </Provider>,
      document.getElementById("root") as HTMLElement
    );
  } catch (err) {
    console.error("Cannot initialize app", err);
  }
};

initGeo3dAndPicassoClientThenRenderWhenReady();
