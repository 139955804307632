import {
  isSolidMesh,
  Render3DContextFilters,
} from "cadius-cadlib";
import {
  DebugToggles,
  Grid,
  GridCell,
  Header,
  IconName,
  NewMaterialFormData,
  NewMaterialModalForm,
  ScrollableColumn,
  TogglesBar,
  ToolButton,
} from "cadius-components";
import { Project } from "cadius-db";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { MaterialsConnected } from "../containers/MaterialsConnected";
import { NotesConnected } from "../containers/NotesConnected";
import { ProjectViewConnected } from "../containers/ProjectViewConnected";
import { TabsConnected } from "../containers/TabsConnected";
import { UiMode } from "../interfaces";

// Variable injected in the bundle at build time (see webpack config).
declare var DEF_DEBUG_MODE: string;

interface IProps extends RouteComponentProps<{ id?: string }> {
  filters: Render3DContextFilters;
  indexSelectedMaterial?: number;
  project?: Project;
  projectId: string;
  uiMode: UiMode;
  styleProjectName?: string;
  remeshingProjectName?: string;

  fetchProject: (id: string) => Promise<void>;
  onNewMaterial: (data: NewMaterialFormData) => void;
  saveProject: (index: string, prj: Project) => void;
  selectNewNotePosition: () => void;
  setProjectId: (projectId: string) => void;
  toggleLast: () => void;
  toggleLines: () => void;
  togglePieces: () => void;
  toggleWireframe: () => void;
  toggleDecorations: () => void;
}

/**
 * Component to show when the user navigates to `projects/:id`
 * @class ProjectRouteImpl
 * @extends {React.Component<IProps>}
 */
export class ProjectNewRoute extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
    this.handleLinesTagClick = this.handleLinesTagClick.bind(this);
    this.handlePiecesTagClick = this.handlePiecesTagClick.bind(this);

    this.showVertexNormals = this.showVertexNormals.bind(this);
    this.showLoopNormals = this.showLoopNormals.bind(this);
    this.showDegenerateLoops = this.showDegenerateLoops.bind(this);

    this.clearDebug = this.clearDebug.bind(this);

    this.saveState = this.saveState.bind(this);
    this.restoreState = this.restoreState.bind(this);

    this.state = { styleProjectName: "", remeshingProjectName: "" };
  }

  public componentDidMount() {
    const idFromRoute = this.props.match.params.id;
    if (idFromRoute) {
      this.props.setProjectId(idFromRoute);
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    // A user could enter a project ID directly in the address bar. When this
    // occurs, the new RouteComponentProps will be different from the previous
    // ones. Note that "" is not a valid project ID.
    const idFromRoute = this.props.match.params.id;
    if (idFromRoute && idFromRoute !== prevProps.match.params.id) {
      this.props.setProjectId(idFromRoute);
    }

    if (this.props.projectId && this.props.projectId !== prevProps.projectId) {
      this.props.fetchProject(this.props.projectId);
    }
  }

  public render() {
    const {
      showDecorations,
      showLast,
      showLines,
      showPieces,
      showWireframe,
    } = this.props.filters;

    const areDebugTogglesVisible =
      DEF_DEBUG_MODE && this.props.project !== null ? true : false;

    const templateAreas = areDebugTogglesVisible
      ? ["header sidebar", "scene sidebar", "debug-toggles sidebar"]
      : ["header sidebar", "scene sidebar"];

    const templateRows = areDebugTogglesVisible
      ? "0.5fr 9fr 0.5fr"
      : "0.5fr 9.5fr";

    return (
      <Grid
        templateAreas={templateAreas}
        templateColumns={"7.5fr 500px"}
        templateRows={templateRows}
      >
        <GridCell gridArea={"header"}>
          <Header
            lastName={this.props.remeshingProjectName}
            projectName={this.props.styleProjectName}
          />
          <TogglesBar
            isLastShown={showLast}
            toggleLast={this.props.toggleLast}
          />
        </GridCell>
        <GridCell gridArea={"scene"}>
          <ProjectViewConnected />
        </GridCell>
        <GridCell backgoundColor={"#4E5B66"} gridArea={"sidebar"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              padding: "1rem",
            }}
          >
            <div>
              <TabsConnected />
            </div>
            <div style={{ display: "flex", flexGrow: 1, overflowY: "auto" }}>
              {this.props.uiMode === UiMode.ANNOTATE ? (
                <div className="stack" style={{ width: "100%" }}>
                  <ToolButton
                    iconName={IconName.New}
                    onClick={this.props.selectNewNotePosition}
                  >
                    Add new note
                                    </ToolButton>
                  <ScrollableColumn>
                    <NotesConnected />
                  </ScrollableColumn>
                </div>
              ) : (
                  <div className="stack" style={{ width: "100%" }}>
                    <NewMaterialModalForm onSubmit={this.props.onNewMaterial} />
                    <ScrollableColumn>
                      <MaterialsConnected />
                    </ScrollableColumn>
                  </div>
                )}
            </div>
          </div>
        </GridCell>
        {areDebugTogglesVisible && (
          <GridCell gridArea={"debug-toggles"}>
            <DebugToggles
              areLinesShown={showLines}
              arePiecesShown={showPieces}
              areDecorationsShown={showDecorations}
              toggleDecorations={this.props.toggleDecorations}
              toggleLast={this.props.toggleLast}
              toggleLines={this.props.toggleLines}
              togglePieces={this.props.togglePieces}
              toggleWireframe={this.props.toggleWireframe}
              clearDebugView={this.clearDebug}
              isLastShown={this.props.filters.showLast}
              isWireframeShown={showWireframe}
              showVertexNormals={this.showVertexNormals}
              showLoopNormals={this.showLoopNormals}
              showDegenerateLoops={this.showDegenerateLoops}
              saveState={this.saveState}
              restoreState={this.restoreState}
            />
          </GridCell>
        )}
      </Grid>
    );
  }

  private handleLinesTagClick(event: React.MouseEvent) {
    event.preventDefault();
    this.props.toggleLines();
  }

  private handlePiecesTagClick(event: React.MouseEvent) {
    event.preventDefault();
    this.props.togglePieces();
  }

  private clearDebug() {
    window.C.debug.clear();
  }

  private showVertexNormals() {
    const g = this.props.project!.fundamentalEntities.last.geometry();
    if (isSolidMesh(g)) {
      window.C.debug.showVerticesNormal(g);
    }
  }

  private showLoopNormals() {
    const g = this.props.project!.fundamentalEntities.last.geometry();
    if (isSolidMesh(g)) {
      window.C.debug.showLoopsNormal(g);
    }
  }

  private showDegenerateLoops() {
    const g = this.props.project!.fundamentalEntities.last.geometry();
    if (isSolidMesh(g)) {
      window.C.debug.showDegenerateLoops(g);
    }
  }

  private saveState() {
    window.C.debug.saveDebugState();
    window.C.debug.saveCamera();
  }

  private restoreState() {
    window.C.debug.restoreCamera();
    window.C.debug.restoreDebugState();
  }
}
