import { ScreenPosition } from "cadius-cadlib";
import { BaseInteractor, Cad, ReactiveView } from "cadius-components";

import { Cad3DAppControl } from "../controls/controls";
import { IApplicationState } from "../interfaces";

/**
 * TODO `nopControl` and the following `CameraControllerInteractor` implementation are just a temporary workaround to
 * make this application works. There should be no application specific camera controller and all interactor has to
 * belong to the `cadius-components` package.
 */
const nopControl = new Cad3DAppControl((state: IApplicationState, v: any) => state);

// TODO: derive from the camera controller base class defined in cadius-components
export class CameraControllerInteractor extends BaseInteractor<any> {
  private _isActive: boolean;
  private _lastPosition: ScreenPosition;

  constructor() {
    super("CameraController", nopControl);
    this._isActive = false;
    this._lastPosition = new ScreenPosition();
  }

  protected onMouseMotionEvent(evt: Cad.MouseMotionEvent) {
    if (!this._isActive) {
      return false;
    }

    let cadView: ReactiveView;
    if (isRotatingEvent(evt)) {
      cadView = this.rotate(evt);
    } else if (isPanningEvent(evt)) {
      cadView = this.pan(evt);
    } else {
      return false;
    }

    Cad3DAppControl.state = {
      ...Cad3DAppControl.state,
      view: {
        ...Cad3DAppControl.state.view,
        cadView,
      },
    };

    // Update last position AFTER the panning/rotation are computed
    this._lastPosition = evt.position;
    return true;
  }

  protected onMousePressEvent(evt: Cad.MousePressEvent) {
    if (!this.isTriggerEvent(evt)) {
      return false;
    }

    this._isActive = true;
    this._lastPosition = evt.position;
    return true;
  }

  protected onMouseReleaseEvent(evt: Cad.MouseReleaseEvent) {
    if (!this.isTriggerEvent(evt)) {
      return false;
    }

    this._isActive = false;
    return true;
  }

  protected onMouseWheelEvent(evt: Cad.MouseWheelEvent) {
    const state = Cad3DAppControl.state;
    const cadView = state.view.cadView;
    const zoomGain = evt.hasModifiers(Cad.Modifier.SHIFT) ? 0.1 : 1;
    const zoom = evt.offset.y * zoomGain;
    Cad3DAppControl.state = {
      ...state,
      view: { ...state.view, cadView: cadView.zoom(zoom) },
    };
    return true;
  }

  private pan(evt: Cad.MouseMotionEvent): ReactiveView {
    const cadView = Cad3DAppControl.state.view.cadView;
    const delta = cadView.computePanningDelta(this._lastPosition, evt.position);
    return cadView.pan(delta);
  }

  private rotate(evt: Cad.MouseMotionEvent): ReactiveView {
    const cadView = Cad3DAppControl.state.view.cadView;
    const rotation = cadView.computeRotationDelta(this._lastPosition, evt.position);
    return cadView.rotate(rotation);
  }

  private isTriggerEvent(evt: Cad.MouseButtonEvent) {
    const conditionA =
      evt.button === Cad.MouseButton.MIDDLE &&
      (evt.hasNoModifier() || evt.hasOnlyModifiers(Cad.Modifier.SHIFT));
    const conditionB =
      evt.button === Cad.MouseButton.LEFT &&
      (evt.hasOnlyModifiers(Cad.Modifier.ALT) ||
        evt.hasOnlyModifiers(Cad.Modifier.ALT | Cad.Modifier.SHIFT));
    return conditionA || conditionB;
  }

}

const isPanningEvent = (evt: Cad.MouseMotionEvent): boolean => {
  return (
    (evt.hasOnlyButtons(Cad.MouseButton.MIDDLE) && evt.hasOnlyModifiers(Cad.Modifier.SHIFT)) ||
    evt.hasOnlyButtons(Cad.MouseButton.LEFT) && evt.hasOnlyModifiers(
      Cad.Modifier.SHIFT | Cad.Modifier.ALT
    )
  );
};

const isRotatingEvent = (evt: Cad.MouseMotionEvent): boolean => {
  return (
    (evt.hasOnlyButtons(Cad.MouseButton.MIDDLE) && evt.hasNoModifier()) ||
    (evt.hasOnlyButtons(Cad.MouseButton.LEFT) && evt.hasOnlyModifiers(Cad.Modifier.ALT))
  );
};
